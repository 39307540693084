import iconOne from "../../../../data/images/icons/icon-07-60x60.png"
import iconTwo from "../../../../data/images/icons/icon-08-60x60.png"
import iconThree from "../../../../data/images/icons/icon-09-60x60.png"
import iconFour from "../../../../data/images/icons/icon-10-60x60.png"

export const data = [
    {
        id: "box-icon-3-1",
        title: "Quality Assurance System",
        desc: "Quis esse ex Lorem aliqua do tempor sunt nostrud pariatur. Quis labore aute culpa ex fugiat occaecat excepteur sunt sint labore ad labore adipisicing.",
        path: "/",
        icon: iconOne
    },
    {
        id: "box-icon-3-2",
        title: "Accurate Testing Processes",
        desc: "Ipsum occaecat nisi ex enim ullamco mollit ullamco laborum cillum reprehenderit fugiat elit anim. Culpa aliqua ad enim est mollit tempor eu laborum ipsum.",
        path: "/",
        icon: iconTwo
    },
    {
        id: "box-icon-3-3",
        title: "Smart API Generation",
        desc: "Ipsum occaecat nisi ex enim ullamco mollit ullamco laborum cillum reprehenderit fugiat elit anim. Culpa aliqua ad enim est mollit tempor eu laborum ipsum.        ",
        path: "/",
        icon: iconThree
    },
    {
        id: "box-icon-3-4",
        title: "Commodo aute magna nisi aute",
        desc: "Magna proident aliquip adipisicing magna proident sit amet. Reprehenderit veniam ullamco do incididunt in commodo non amet.       ",
        path: "/",
        icon: iconFour
    }
]